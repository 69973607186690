import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../../helpers";
import MaybeClassProps from "../MaybeClassProps.type";

const ReferenceList: FunctionComponent<MaybeClassProps> = ({
  children,
  className,
}) => {
  return (
    <ol className={mergeClassNames("References__List", className)}>
      {children}
    </ol>
  );
};

export default ReferenceList;
