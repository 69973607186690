import ButtonBase from "./ButtonBase";
import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../../helpers";

import ButtonProps from "./ButtonProps.type";

const ButtonPrimary: FunctionComponent<ButtonProps> = props => {
  const { children, className } = props;

  return (
    <ButtonBase
      {...props}
      className={mergeClassNames(
        className,
        "transition-bg bg-green hover:bg-green-dark text-white shadow-button"
      )}
    >
      {children}
    </ButtonBase>
  );
};

export default ButtonPrimary;
