import React, { FunctionComponent } from "react";
import DonutGraph from "../components/DonutGraph/DonutGraph";
import { mergeClassNames } from "../helpers";
import MaybeClassProps from "../components/MaybeClassProps.type";

const NotMonitored: FunctionComponent<MaybeClassProps> = ({ className }) => {
  return (
    <div
      className={mergeClassNames(
        "flex-col md:flex-row items-center justify-center md:h-full",
        className
      )}
    >
      <DonutGraph
        className="w-full px-16 md:w-1/2 md:px-2"
        percent={0.62}
        alt="Donut graph showing 62%"
        maxWidth={200}
      />
      <div className="w-full md:w-1/2 text-center md:text-left text-red text-lg md:text-2xl font-bold antialiased px-8 md:pl-0 md:pr-12 leading-none">
        of CHB patients are not monitored for HBV DNA
      </div>
    </div>
  );
};

export default NotMonitored;
