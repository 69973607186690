import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

import Img from "gatsby-image";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import ButtonPrimary from "../components/Buttons/ButtonPrimary";

import HeroImage from "../components/HeroImage/HeroImage";
import heroBox from "../images/home/hero-box.svg";
import heroBoxWithoutAnimation from "../images/home/hero-box-without-animation.svg";
import heroBoxMobile from "../images/home/hero-box-mobile.svg";
import heroBoxMobileWithoutAnimation from "../images/home/hero-box-mobile-without-animation.svg";
import Slab from "../components/Slab";
import Subheading from "../components/Subheading/Subheading";
import TwoOfThree from "../partials/TwoOfThree";
import AssetCardGroup from "../components/AssetCard/AssetCardGroup";
import References from "../components/References/References";
import ReferenceList from "../components/References/ReferenceList";
import ReferenceItem from "../components/References/ReferenceItem";
import NotMonitored from "../partials/NotMonitored";
import ScreeningGuidelines from "../partials/AssetCards/ScreeningGuidelines";
import Strong from "../components/Strong";
import Paragraph from "../components/Paragraph";
import Footnotes from "../components/Footnotes";
import Abbr from "../components/Abbr/Abbr";
import AbbrList from "../components/Abbr/AbbrList";
import "../helpers";
import tracker from "../tracker";
import ImageWrapper from "../components/ImageWrapper";
import Parallax from "../components/Parallax/Parallax";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "home/hero.jpg" }) {
        ...HeroQuery
      }
      heroMobile: file(relativePath: { eq: "home/hero-mobile.png" }) {
        ...HeroQuery
      }
      atRiskPatients: file(relativePath: { eq: "home/at-risk-patients.png" }) {
        childImageSharp {
          fluid(maxWidth: 362) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      connectPatients: file(relativePath: { eq: "home/connect-patients.png" }) {
        childImageSharp {
          fluid(maxWidth: 495) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      footerMan: file(relativePath: { eq: "footer-man.png" }) {
        childImageSharp {
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pillMillions: file(
        relativePath: { eq: "home/pill-2.2-million-people.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 650, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      pillUpTo30: file(relativePath: { eq: "home/pill-up-to-30-percent.png" }) {
        childImageSharp {
          fluid(maxWidth: 650, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      page: trackingJson(id: { eq: "index" }) {
        subject
      }
      takeActionNow: trackingJson(id: { eq: "takeActionNow" }) {
        ...TrackingQuery
      }
      whosAtRisk: trackingJson(id: { eq: "seeWhosAtRisk" }) {
        ...TrackingQuery
      }
      learnAboutScreening: trackingJson(id: { eq: "learnAboutScreening" }) {
        ...TrackingQuery
      }
      seeTreatmentGuidelines: trackingJson(
        id: { eq: "seeTreatmentGuidelines" }
      ) {
        ...TrackingQuery
      }
      reviewGuidelines: trackingJson(id: { eq: "reviewGuidelines" }) {
        ...TrackingQuery
      }
      downloadTheGuide: trackingJson(id: { eq: "downloadTheGuide" }) {
        ...TrackingQuery
      }
    }
  `);
  useEffect(() => {
    window.dataLayer.push(data.page);
  }, []);

  const heroHeadLine = (
    <span>
      1 in ~150 people have chronic hepatitis&nbsp;B.
      <sup className="whitespace-no-wrap">1-3,a</sup>{" "}
      <br className="hidden lg:inline" />
      Are your patients among them?
    </span>
  );
  const heroFooter = (
    <>
      <ButtonPrimary
        to="#screen-for"
        jump
        tracking={tracker(data.takeActionNow)}
      >
        Take Action Now
      </ButtonPrimary>
      <div className="text-xs text-white pt-8">
        <sup>a</sup>Based on 2012 estimate for total prevalence of CHB in the
        United States<sup>1</sup> extrapolated to 2019 US population.
        <sup>3</sup>
      </div>
    </>
  );
  const heroPosition = {
    desktop: {
      x: 50,
      y: 50,
    },
    mobile: {
      x: 0,
      y: 0,
    },
  };

  return (
    <Layout>
      <SEO
        title="Chronic Hepatitis B Information for Healthcare Professionals"
        description="HepBMD® helps healthcare professionals navigate the challenges associated with screening, management and treatment of patients with chronic hepatitis B."
      />
      <HeroImage
        headLine={heroHeadLine}
        footer={heroFooter}
        fluidQuery={data.hero.childImageSharp.fluid}
        mobileFluidQuery={data.heroMobile.childImageSharp.fluid}
        boxSrc={heroBox}
        boxSrcWithoutAnimation={heroBoxWithoutAnimation}
        homeMobileBoxSrc={heroBoxMobile}
        homeMobileBoxSrcWithoutAnimation={heroBoxMobileWithoutAnimation}
        imagePosition={heroPosition}
        isHomeHero={true}
      />
      <Slab name="identify-at-risk-patients">
        <div className="md:flex">
          <div className="w-full md:w-2/3 lg:w-1/2">
            <Subheading mobileCenter>Identify at-risk patients</Subheading>
            <ImageWrapper maxWidth={458} left className="mx-auto md:mx-0">
              <Img
                fluid={data.pillMillions.childImageSharp.fluid}
                className="my-8 md:my-4"
                alt="About 2.2 million people in the US have CHB"
              />
            </ImageWrapper>
            <Paragraph className="text-center md:text-left pr-16">
              Certain at-risk populations experience a higher incidence of HBV
              infection.<sup>4</sup>
            </Paragraph>
            <AbbrList>
              <Abbr term="CHB" title="chronic hepatitis B" />
              <Abbr term="HBV" title="hepatitis B virus." />
            </AbbrList>
            <Footnotes>
              <br />
              <sup>b</sup>2012 estimate.<sup>1</sup>
            </Footnotes>

            <div className="py-4 text-center md:text-left">
              <ButtonPrimary
                to="/hepatitis-b-risk"
                tracking={tracker(data.whosAtRisk)}
              >
                See Who's at Risk
              </ButtonPrimary>
            </div>
          </div>
          <div className="w-full md:w-1/3 lg:w-1/2 flex flex-col self-end h-72 md:h-auto">
            <div className="z:0 -mb-96 sm:mx-12 md:mx-0 md:-mb-12 lg:-mb-24 lg:px-12">
              <Parallax speed={-1} percentage={0.2}>
                <ImageWrapper maxWidth={362}>
                  <Img fluid={data.atRiskPatients.childImageSharp.fluid} />
                </ImageWrapper>
              </Parallax>
            </div>
          </div>
        </div>
      </Slab>
      <Slab name="screen-for" className="bg-grey relative">
        <div className="md:flex">
          <div className="w-full md:w-1/2">
            <TwoOfThree className="hidden md:block" />
          </div>
          <div className="w-full md:w-1/2">
            <Subheading mobileCenter className="w-full">
              Screen for and diagnose hep&nbsp;B
            </Subheading>
            <TwoOfThree className="block md:hidden" />
            <Paragraph>
              About 2 out of 3 people living with CHB in the US are unaware of
              their infection.<sup>5</sup>
            </Paragraph>
            <div className="py-4 text-center md:text-left">
              <ButtonPrimary
                to="/hepatitis-b-screening-diagnosis"
                tracking={tracker(data.learnAboutScreening)}
              >
                Learn About Screening
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </Slab>
      <Slab>
        <Subheading mobileCenter>Connect patients to treatment</Subheading>
        <div className="md:flex">
          <div className="w-full md:w-3/5 lg:w-1/2">
            <ImageWrapper maxWidth={446} left className="mx-auto md:mx-0">
              <Img
                fluid={data.pillUpTo30.childImageSharp.fluid}
                className="my-4"
                alt="up to 30% of people with CHB develop liver problems"
              />
            </ImageWrapper>
            <Paragraph className="text-center md:text-left">
              <strong>20% to 30% of people </strong> with CHB develop liver
              problems such as cirrhosis, HCC or liver failure.<sup>6</sup>
            </Paragraph>
            <Paragraph className="text-center md:text-left">
              <Strong>25%</Strong> die prematurely of these conditions.
              <sup>7</sup>
            </Paragraph>
            <Footnotes className="text-center md:text-left text-sm pb-4">
              <Abbr term="HCC" title="hepatocellular carcinoma." />
            </Footnotes>
            <div className="py-4 text-center md:text-left">
              <ButtonPrimary
                to="/hepatitis-b-treatment"
                tracking={tracker(data.seeTreatmentGuidelines)}
              >
                See Treatment Guidelines
              </ButtonPrimary>
            </div>
          </div>
          <div className="w-full md:w-2/5 lg:w-1/2 flex flex-col justify-end h-64 md:h-auto">
            <div className="z:0 -mb-48 md:-mb-24">
              <Parallax speed={0.5} percentage={0.2}>
                <ImageWrapper maxWidth={495}>
                  <Img fluid={data.connectPatients.childImageSharp.fluid} />
                </ImageWrapper>
              </Parallax>
            </div>
          </div>
        </div>
      </Slab>
      <Slab className="bg-grey-medium relative">
        <div className="md:flex">
          <div className="w-full md:w-1/2">
            <NotMonitored className="hidden md:flex" />
          </div>
          <div className="w-full md:w-1/2">
            <Subheading mobileCenter className="w-full">
              Manage chronic hepatitis&nbsp;B
            </Subheading>
            <NotMonitored className="flex md:hidden" />
            <Paragraph className="text-center md:text-left">
              62% of CHB patients are never or not routinely monitored for HBV
              DNA.<sup>6</sup> AASLD guidance recommends ongoing evaluation and
              monitoring are essential components of disease management.
              <sup>7</sup>
            </Paragraph>
            <div className="py-5 text-center md:text-left">
              <ButtonPrimary
                to="/hepatitis-b-management"
                tracking={tracker(data.reviewGuidelines)}
              >
                Review Guidelines
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </Slab>
      <Slab>
        <div className="md:flex">
          <div className="w-full md:w-2/3">
            <Subheading level={3} pad>
              Information for healthcare professionals
            </Subheading>
            <AssetCardGroup>
              <ScreeningGuidelines
                className="lg:w-3/4 lg:pr-4"
                tracking={tracker(data.ScreeningGuidelines)}
              />
            </AssetCardGroup>
            <AbbrList>
              <Abbr
                term="AASLD"
                title="American Association for the Study of Liver Diseases"
              />
              <Abbr term="ACP" title="American College of Physicians" />
              <Abbr
                term="CDC"
                title="Centers for Disease
              Control and Prevention"
              />
              <Abbr
                term="USPSTF"
                title="U.S. Preventive Services Task
              Force."
              />
            </AbbrList>
            <References>
              <ReferenceList>
                <ReferenceItem>
                  Gish R, Cohen C, Block J, et al. Data supporting updating
                  estimates of the prevalence of chronic hepatitis B and C in
                  the United States. <em>Hepatology</em>. 2015;62:1339-1341.
                  doi:10.1002/hep.28026
                </ReferenceItem>
                <ReferenceItem>
                  Kowdley K, Wang C, Welch S, et al. Prevalence of chronic
                  hepatitis B among foreign-born persons living in the United
                  States by country of origin. <em>Hepatology</em>.
                  2012;56:422-433. doi:10.1002/hep.24804
                </ReferenceItem>
                <ReferenceItem>
                  U.S. Census Bureau. U.S. and World Population Clock. Accessed
                  October 1, 2019. <span>https://www.census.gov/popclock/</span>
                </ReferenceItem>
                <ReferenceItem>
                  Terrault NA, Lok ASF, McMahon BJ, et al. Update on prevention,
                  diagnosis, and treatment of chronic hepatitis B: AASLD 2018
                  hepatitis B guidance. <em>Hepatology</em>.
                  2018;67(4):1560-1599. doi:10.1002/hep.29800
                </ReferenceItem>
                <ReferenceItem>
                  Cohen C, Holmberg SD, McMahon BJ, et al. Is chronic hepatitis
                  B being undertreated in the United States?
                  <em>J&nbsp;Viral Hepat</em>. 2011;18:377-383.
                  doi:10.1111/j.1365-2893.2010.01401.x
                </ReferenceItem>
                <ReferenceItem>
                  World Health Organization. Guidelines for the prevention, care
                  and treatment of persons with chronic hepatitis B infection.
                  Geneva, Switzerland: World Health Organization; 2015.
                </ReferenceItem>
                <ReferenceItem>
                  Abara W, Qaseem A, Schillie S, et al. Hepatitis B vaccination,
                  screening, and linkage to care: best practice advice from the
                  American College of Physicians and the Centers for Disease
                  Control and Prevention. <em>Ann Intern Med</em>.
                  2017;167(11):794-804. doi:10.7326/M17-1106
                </ReferenceItem>
                {/* <ReferenceItem>
                  Spradling P, Xing J, Rupp L, et al. Infrequent clinical
                  assessment of chronic hepatitis B patients in United States 
                  general healthcare settings. <em>Clin Infect Dis.</em>
                   2016;63:1205-1208. doi:10.1093/cid/ciw516
                </ReferenceItem> */}
              </ReferenceList>
            </References>
          </div>
          <div className="w-full md:w-1/3 flex flex-col justify-end pt-8">
            <div className="z:0 -mb-72 md:-mb-24">
              <ImageWrapper maxWidth={308}>
                <Img fluid={data.footerMan.childImageSharp.fluid} />
              </ImageWrapper>
            </div>
          </div>
        </div>
      </Slab>
    </Layout>
  );
};

export default IndexPage;
