import React, { FunctionComponent } from "react";
import { ReactComponent as TwoOfThreeIcon } from "../images/icons/two-of-three-people.svg";
import { mergeClassNames } from "../helpers";

type Props = {
  className?: string;
};
const TwoOfThree: FunctionComponent<Props> = ({ className }) => {
  return (
    <div
      className={mergeClassNames(
        "h-full w-full md:pr-24 flex flex-col items-center justify-center text-red pt-4",
        className
      )}
    >
      <div className="font-bold antialiased text-center">
        <div className="text-5xl leading-none">
          <span className="font-normal">~</span>2 out of 3
        </div>
        <div className="text-xl leading-none">are unaware</div>
      </div>
      <div className="flex w-full justify-around pt-4 px-4 md:px-0">
        <TwoOfThreeIcon />
      </div>
    </div>
  );
};

export default TwoOfThree;
